import { Tokens, tokensConfig } from '~/utils/constants';
import { parseUnits } from 'ethers';
import type { SendParams } from '~/types/Web3Provider';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { useEnvs, useMainStore } from '#imports';

type Web3Confirmation = (confirmations?: number) => void;

export default () => {
  const { address } = useWeb3ModalAccount();
  const { sendContractMethod } = useSendContractMethod();
  const { blockchain } = useEnvs();
  const store = useMainStore();
  const { getContract } = useAbiAccess();

  const sendContractMethodExtended = async (sendParams: SendParams, onConfirmation?: Web3Confirmation) => {
    const from = address.value;
    return sendContractMethod({ ...sendParams, from }, onConfirmation);
  };

  const claimMinedTokens = (onConfirmation?: Web3Confirmation, tokenName: Tokens = store.currentToken) => {
    return sendContractMethodExtended(
      {
        contract: 'mine',
        address: blockchain.contracts[tokenName].addresses.mine || '',
        methodName: 'claim'
      },
      onConfirmation
    );
  };

  /**
   * buyLicense
   * @param value - human readable value like 0.1 USDT
   * @param onConfirmation - confirmation callback, fired on each head block
   */
  const buyLicense = (value: string, tokenName: Tokens, onConfirmation?: Web3Confirmation) => {
    const amount = parseUnits(value, 'ether');

    return sendContractMethodExtended(
      {
        contract: 'mine',
        address: blockchain.contracts[tokenName].addresses.mine!,
        methodName: 'buyLicense',
        methodArguments: [amount]
      },
      onConfirmation
    );
  };

  const buyLicenseAndRegisterInReferralProgram = async (
    value: string,
    tokenName: Tokens,
    referrerAddress: string,
    onConfirmation?: Web3Confirmation
  ) => {
    const amount = parseUnits(value, 'ether');
    const storeContract = await getContract('mine', blockchain.contracts[tokenName].addresses.mine!);
    const iface = storeContract?.interface;
    return sendContractMethodExtended(
      {
        contract: 'mine',
        address: blockchain.contracts[tokenName].addresses.mine!,
        methodName: 'multicall',
        methodArguments: [
          [
            iface?.encodeFunctionData('registerReferral', [referrerAddress]),
            iface?.encodeFunctionData(`buy${tokensConfig[tokenName].methodName.split(' ').join('')}Pack`, [amount])
          ]
        ]
      },
      onConfirmation
    );
  };

  return {
    buyLicense,
    buyLicenseAndRegisterInReferralProgram,
    claimMinedTokens
  };
};
