export const path = {
  withAuth: {
    referral: '/referral'
  },
  public: {
    home: '/',
    dashboard: '/dashboard',
    marketplace: '/marketplace',
    playground: '/spine-playground',
    story: '/story'
  }
};
