import { BigNumber } from 'bignumber.js';

export const formatToZeroPrefixedNumber = (value: number | string) =>
  BigNumber(value).isPositive() && BigNumber(value).gte(10)
    ? BigNumber(value).toString()
    : `0${BigNumber(value).toString()}`;

export const formateDate = (date: string) => new Date(date).toLocaleTimeString('en-GB');

// format of pearl name like goldOre to 'gold ore' adding spaces
export const addSpaces = (pearlMethod: string): string => {
  return pearlMethod.replace(/([A-Z])/g, ' $1').trim();
};

export const format = (value: string | number, decimals = 2) => {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
};

export const formatLeaderBoardText = (text: string | number): string | number => {
  const maximum = 100;
  return Number(text) > maximum ? `${maximum}+` : text;
};

export function capitalizeFirstLetter(value: string): string {
  return String(value).charAt(0).toUpperCase() + String(value).slice(1);
}
